import { React, useState, useEffect, useRef } from 'react'
import '../styles/Home.css'

const Home = () => {
    // const [index, setIndex] = useState(0);
    // const intervalId = useRef(null);

    // useEffect(() => {
    //     intervalId.current = setInterval(() => {
    //         setIndex((index) => index + 1);
    //     }, 6500);
    //     return () => clearInterval(intervalId.current);
    // }, []);

    // const pauseCarousel = () => {
    //     clearInterval(intervalId.current);
    // };

    // const resumeCarousel = () => {
    //     clearInterval(intervalId.current); // Clear the existing interval
    //     intervalId.current = setInterval(() => {
    //         setIndex((index) => index + 1);
    //     }, 6500);
    // };

    return (
        <div className="home">
            <div className='cover'>
                <h1 className='header'>
                    Welcome to the Faustlink Website
                </h1>
            </div>
        </div>
    );
}

export default Home