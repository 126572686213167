import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import Contact from './components/Contact';
import FAQ from './components/FAQ';
import Team from './components/Team';
import MaintenancePage from './components/maintenance-mode/MaintenancePage';

function App() {
  return (
    <div className='app'>
      <MaintenancePage />
    </div>
  );
}

export default App;
