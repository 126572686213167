import React from 'react'
import '../../styles/maintenance-mode/MaintenancePage.css'
import logo from '../../media/FL_Logo.png'
import Lottie from 'react-lottie'
import animationData from '../../animations/maitenance-animation.json'


const MaintenancePage = () => {
    return (
        <div className='maintenance-page'>
            <img src={logo} alt='Faustlink Logo' className='logo' />

            <div className='content'>
                <div className='animation' >
                    <Lottie options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        pause: false,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    />
                </div>

                <h3>We are currently improving our site to serve you better.</h3>
                <p>We will be back soon.</p>
            </div>
        </div>
    )
}

export default MaintenancePage